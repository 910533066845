import * as React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import App from './App';
import { CheckoutDomesticProvider } from './context/CheckoutDomesticContext';
import Compose from './context/Compose';
import { ErrorProvider } from './context/ErrorContext';
import reportWebVitals from './reportWebVitals';
import { initMocks } from './test/server';

initMocks();

ReactDOM.render(
  <React.StrictMode>
    <Compose components={[ErrorProvider, CheckoutDomesticProvider]}>
      <App />
    </Compose>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
