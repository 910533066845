const storagePrefix = 'coreyo_';

const storage = {
  getToken: () => {
    try {
      return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string);
    } catch (error) {
      return '';
    }
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
};

export default storage;
