import { createContext, useState, useContext } from 'react';

interface ICheckoutDomesticContextData {
  fireStoreId: string | undefined;
  setFireStoreId(firestoreId: string | undefined): void;
}

interface ICheckoutDomesticContextProps {
  children?: React.ReactNode;
}

const CheckoutDomesticContext = createContext<ICheckoutDomesticContextData>(
  {} as ICheckoutDomesticContextData
);

export const CheckoutDomesticProvider = ({ children }: ICheckoutDomesticContextProps) => {
  const [fireStoreId, setFireStoreId] = useState<string | undefined>();

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CheckoutDomesticContext.Provider value={{ fireStoreId, setFireStoreId }}>
      {children}
    </CheckoutDomesticContext.Provider>
  );
};

// default props
CheckoutDomesticProvider.defaultProps = {
  children: undefined,
};

export function useCheckoutDomestic() {
  const context = useContext(CheckoutDomesticContext);
  return context;
}
