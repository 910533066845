import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'react-hot-toast';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import { Button, Spinner } from '@/components/Elements';
import { COMING_SOON, SITE_DISABLED } from '@/config';
import { SiteUnderMaintainence } from '@/features/misc/components/SiteUnderMaintainence';
import { AuthProvider } from '@/lib/auth';
import { queryClient } from '@/lib/react-query';

function ErrorFallback() {
  return (
    <div
      className="flex flex-col items-center justify-center w-screen h-screen text-red-500"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <Button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </Button>
    </div>
  );
}

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  console.log(COMING_SOON === 'true' || SITE_DISABLED === 'true', 'comingsoon');

  const toastOptions = {
    // Define default options
    className: '',
    duration: 5000,
    style: {
      padding: '16px 20px',

      backgroundColor: '#eaeaec',
    },
  };
  console.log(COMING_SOON === 'true' || SITE_DISABLED === 'true', 'comingsoon');
  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner size="xl" />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
          <Toaster position="bottom-center" reverseOrder={false} toastOptions={toastOptions} />
          <AuthProvider>
            {/* <Router>{children}</Router> */}
            {SITE_DISABLED === 'true' ? <SiteUnderMaintainence /> : <Router>{children}</Router>}
          </AuthProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
