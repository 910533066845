import { createContext, useState, useContext } from 'react';

interface IAuthContextData {
  error: boolean;
  setError(error: boolean): void;
}

interface IAuthContextProps {
  children?: React.ReactNode;
}

const ErrorContext = createContext<IAuthContextData>({} as IAuthContextData);

export const ErrorProvider = ({ children }: IAuthContextProps) => {
  const [error, setError] = useState<boolean>(true);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ErrorContext.Provider value={{ error, setError }}>{children}</ErrorContext.Provider>
  );
};

// default props
ErrorProvider.defaultProps = {
  children: null,
};

export function useError() {
  const context = useContext(ErrorContext);
  return context;
}
